export default {
  menu: {
    home: '首页',
    demo: '演示',
    document: '文档',
    classDiagram: '类图工具',
    bop: '面向业务编程',
    logout: '登出',
    login: '登录',
    dark: '暗',
    light: '亮',
    profile: '我的',
    codeDiff: '差异更新',
  },
  diagram: {
    maxLimit: '已达到最大缩放限制',
    minLimit: '图形已完全显示在视图中',
    reset: '图形已适配屏幕',
  },
  guide: {
    input: '主输入区域。最多128字符限制。交互信息在上方显示',
    limit: '可用LLM请求次数',
    language: '亮/暗 主题和界面语言选择',
    control: '图形/文档切换，导出和图形分离/合并操作',
    title: '图形标题信息',
    main: '主图形/文档区域',
    action:
      '本页面是一个用于进行类图编辑的工具。支持创建类、属性以及类之间的关系。您可以进一步查看演示介绍文档以获取更多信息，或者先开始体验然后通过文档菜单了解更多信息。',
    button1: '查看文档',
    button2: '开始工作',
    help: '显示本信息屏幕',
    fitScreen: '调整图形适应屏幕',
  },
  help: '帮助',
  attention: '请注意',
  known: '我知道了',
  powered: '由 deepseek 驱动',
  emailAlert1: '请完成您的电子邮件验证后尝试再登录',
  emailAlert2: '请检查垃圾邮件分类以确认邮件是否到达',
  today: '今天',
  tomorrow: '明天',
  requestQuota: '到{day} {time} 以前还有 {remain}/{total} 请求次数',
  loadingError: '加载错误，请重新刷新页面',
  badCredentials: '用户信息错误，将返回首页',
  actionSuccess: '请求执行成功',
  actionsSuccess: '请求执行成功',
  actionInvalid: '请求无效',
  outOfQuota: '您的LLM请求余额不足',
  commonError: '处理请求执行结果信息失败',
  template: '处理`{instructionName}`请求时`{validationTypeName}`验证失败。请检查`{paramType}`参数的值:{params}',
  noMoreRollback: '没有更多记录可以回滚',
  noMoreTokens: '您的token余额不足，请联系管理员处理',
  restrictionInfo: '了解用量详情',
  register: '登录/注册',
  registerAlert: '请先登录注册后体验',
  operation: {
    document: '文档',
    export: '导出',
    split: '分离',
    merge: '合并',
    diagram: '图形',
    fitScreen: '适应屏幕',
  },
  chat: {
    you: '你',
    pleaseInput: '请输入交互信息',
    info: '消息',
    error: '错误',
    warning: '警告',
    moreHistory: '更多历史消息已经删除',
    maxLengthReached: '已经达到最大长度',
    emptyInfoNotAllow: '请不要提交空信息',
    copy: '复制',
    retry: '重新发送',
    submit: '发送',
    sending: '您的请求正在被处理',
    cancel: '删除内容',
    unrecognizedMessage: '识别失败',
  },
  title: {
    bop: 'BOP',
    homePage: 'BOP - 重新定义软件开发',
    aboutPage: '关于',
    userProfile: '用户资料',
    classDiagram: '类图工具',
    classDiagramDoc: '类图文档',
    classDiagramResults: '类图结果',
    codeDiff: '代码对比工具',
  },
  instructions: {
    '10100': '删除关系',
    '10150': '删除方法',
    '10200': '删除类属性',
    '10300': '删除类',
    '10350': '批量删除类',
    '10400': '添加类',
    '10450': '批量添加类',
    '10500': '添加属性名',
    '10600': '添加或修改双向关系',
    '10700': '添加或修改单向关系',
    '10800': '添加或修改谓词',
    '10900': '添加或修改关系类型',
    '10910': '添加关系类型',
    '10915': '添加方法',
    '10920': '变更关系类型',
    '10930': '变更关系谓词',
    '10940': '变更关系数量',
    '10950': '反转关系方向',
    '11000': '修改属性约束',
    '11100': '修改类属性名',
    '11150': '修改类属性数据类型',
    '11200': '修改类名',
    '11250': '修改方法名',
    '11300': '修改图形名称',
    '11400': '重置类图',
    '11500': '清空类图',
    '11600': '撤销图形操作',
    '20100': '分离视图',
    '20200': '合并视图',
    '20300': '显示文档',
    '20400': '显示图形',
    '20500': '导出文档',
    '20600': '导出图形',
  },
  validateTypes: {
    '100': { validationTypeName: '类名称应当存在', paramType: '类名称' },
    '200': { validationTypeName: '类名称不应当存在', paramType: '类名称' },
    '300': { validationTypeName: '类名称不能为空', paramType: '类名称' },
    '400': { validationTypeName: '类名称应当合法', paramType: '类名称' },
    '500': { validationTypeName: '属性名称应当存在', paramType: '属性名称' },
    '600': { validationTypeName: '属性名称不应当存在', paramType: '属性名称' },
    '700': { validationTypeName: '属性名称不能为空', paramType: '属性名称' },
    '800': { validationTypeName: '属性名称应当合法', paramType: '属性名称' },
    '900': { validationTypeName: '约束应当合法', paramType: '约束' },
    '1000': { validationTypeName: '关系应当存在', paramType: '关系' },
    '1100': { validationTypeName: '关系不应当存在', paramType: '关系' },
    '1200': { validationTypeName: '关系类型应当是关联类型', paramType: '关系类型' },
    '1300': { validationTypeName: '关系类型应当合法', paramType: '关系类型' },
    '1400': { validationTypeName: '关系类型不应当是继承类型', paramType: '关系类型' },
    '1500': { validationTypeName: '关系数量应当合法', paramType: '数量' },
    '1600': { validationTypeName: '谓词应当合法', paramType: '谓词' },
    '1700': { validationTypeName: '谓词不能为空', paramType: '谓词' },
    '1800': { validationTypeName: '类图名称不能为空', paramType: '类图名称' },
    '1900': { validationTypeName: '重置类图参数错误', paramType: '类图名称' },
    '2000': { validationTypeName: '方法名称不能为空', paramType: '方法名' },
    '2100': { validationTypeName: '方法名称无效', paramType: '方法名' },
    '2200': { validationTypeName: '方法已经存在', paramType: '方法名' },
    '2300': { validationTypeName: '方法不存在', paramType: '方法名' },
    '2400': { validationTypeName: '批量操作', paramType: '类图名称' },
    '2500': { validationTypeName: '数据类型无效', paramType: '数据类型' },
    '2600': { validationTypeName: '参数无效', paramType: '参数' },
  },
  serverError: {
    common: '服务异常，请重试或联系网站管理员。',
    unknownerror: '服务异常，请重试或联系网站管理员',
    llmfailed: 'LLM 返回数据错误',
    rendersvgfailed: 'plantUML渲染错误',
    lengthlimit: '对话数据超长错误',
    outofquota: '请求次数耗尽',
    outoftoken: 'token耗尽',
    exportdocfailed: '文档导出错误',
    documentdatafailed: '文档导出错误',
  },
  home: [
    {
      title: '编程的本质是创造',
      contents: [
        '编程是一种艺术，但是编码不是',
        '编程不只是编码，而是一种创造性的思维过程',
        '我们需要重新思考：如何让创造性思维更自然、更高效?',
      ],
    },
    {
      title: '突破传统人机交互的限制',
      contents: [
        '您只需负责思考，让工具负责实现',
        '摒弃繁琐的界面操作，转向自然语言和自然交互',
        '用语音、文字和简单操作随心所欲地表达您的设计意图',
      ],
    },
    {
      title: '自动化文档处理革命',
      contents: [
        '您不喜欢写文档，我也是',
        '我们演示从自由表达直接生成标准化设计文档',
        '我们会让设计即是文档，文档即是设计，文档既是代码',
      ],
    },
    {
      title: '工业化的软件开发新范式',
      contents: [
        '可重复、可验证是工业化的特征',
        '我们提供标准化的业务逻辑处理流程',
        '从您的大脑开始实现软件开发过程全流程的工业化',
      ],
    },
    {
      title: 'LLM驱动的智能协作',
      contents: [
        'AI不仅是工具，更是您的开发伙伴',
        '让每个决策都建立在智能分析的基础上',
        '实时理解您的意图，并提供专业建议和广泛的选择',
      ],
    },
    {
      title: '体验未来，就在当下',
      contents: [
        '率先体验下一代软件开发模式',
        '亲身感受AI辅助开发的革命性变化',
        '面向业务编程，以业务为核心的开发范式已经出现',
      ],
    },
  ],
  profile: {
    title: '个人信息',
    basePart: {
      title: '基础信息',
      userName: '用户名',
      email: '电子邮箱',
    },
    tokenPart: {
      title: 'Token信息',
      content: '作为一个demo，我们只提供有限的体验。如果您需要更多额度，请直接联系作者有关详情',
      remain: '当前剩余',
      added: '累计添加',
      consumed: '累计消耗',
      request: '累计请求',
      avg: '平均消耗',
      addHistory: 'Token获取历史',
      consumeByM: '按月消耗',
      date: '日期',
      count: '数量',
      tokens: '获得量',
      tokenConsumed: '消费量',
      last30: '最近30次请求',
      noData: '暂无数据',
      type: '分类',
      type0: '类图工具',
      type1: '差异更新',
    },
  },
  helpMessage: {
    line1: '欢迎反馈新一代人机交互体验',
    line2: '让AI完成你的愿望',
    line3: '释放您的双手，专注您的设计',
    line4: '请参考文档了解语音输入模式',
    line5: '请参考文档了系统基础能力',
    line6: '请参考文档获得类图编辑基础知识',
  },
  codeDiff: {
    title1: '问题',
    title2: '收益',
    content1:
      '在进行LLM辅助代码编辑时目前几乎所有的工具都使用全量输出文件内容的模式。单个文件的LLM请求等待时间 = 文件token数量/token输出速度。当源代码文件的字符数达到5kb或1ktokne时，每秒50token的输出速度下等待时间接近20秒。在更大文件或更慢输出速度或多文件处理时会占据更多时间，并且请求的输出token长度也是有限的',
    content2:
      '我们的技术方案使得LLM仅返回文件被变更的信息，在大部分情况下使得昂贵的输出token仅被消耗在必要的变更信息以及必须的差异信息描述上。这样可以大大减少请求等待时间，提高效率。文件的变更率越低，差异模式获得的优势越高。平均3-5秒的相应时间，可以支持3-5倍token输出速度的源代码变更数据量。大部分单文件变更可以在6秒内完成，多文件变更可以在10秒内完成',
    patten1: '全量模式',
    patten2: '差异模式',
    requestTime: '耗时(秒)',
    tokenCount: '输出token',
    tokenSpeed: 'token/秒',
    retryCount: '重试次数',
    uploadFile: '请使用上传按钮选择文件',
    lineMode: '逐行模式',
    columnMode: '并排模式',
    diffMode: '差异',
    fullMode: '全量',
    processFailed: '处理失败',
    submitFailed: '提交失败，请重试',
    list1: [
      '大部分LLM工具使用全量模式输出代码修改结果',
      '等待1K token的文件输出需要至少20秒',
      '部分差异模式工作的工具，依赖低效的文本搜索确定变更位置',
      '事实上diff格式可以被LLM有效输出',
    ],
    list2: [
      '避免无效且昂贵的输出token',
      '使得输出tokne与实际变更数量相关',
      '1500字节/300token内的变更等待时间仅需要3-6秒',
      '降低50%-90%的输出token成本',
    ],
    learnMore: '了解更多',
    fullModeWarning: '全量模式下提交大于8kb的文件会因为输出token限制而失败',
  },
};
