// prettier-ignore
export default {
  menu: {
    home: 'Home',
    demo: 'Demo',
    document: 'Document',
    classDiagram: 'Class Diagram Tool',
    bop: 'Business-Oriented Programming',
    logout: 'Logout',
    login: 'Login',
    dark: 'Dark',
    light: 'Light',
    profile: 'Profile',
    codeDiff: 'Diff Update',
  },
  diagram: {
    maxLimit: 'Maximum zoom limit reached',
    minLimit: 'The entire diagram is fully visible in the view',
    reset: 'Graphics have been adapted to the screen',
  },
  guide: {
    input: 'Main input area. Maximum 128 characters limit. Interaction information is displayed above.',
    limit: 'LLM request remaining',
    language: 'Light/Dark theme and language selection',
    control: 'Graph/Document toggle, export, and graph split/merge operations',
    title: 'Graph title information',
    main: 'Main graph/document area',
    action:
'This page is a tool for editing class diagrams. It supports creating classes, attributes, and relationships between classes. You can further view the demo introduction document for more information, or start the experience first and then learn more through the document menu.',
    button1: 'View Document',
    button2: 'Start Work',
    help: 'Display this help screen',
    fitScreen: 'Fit graphics to screen',
  },
  help: 'Help',
  attention: 'Please pay attention',
  known: 'I know',
  powered: 'Powered by deepseek',
  emailAlert1: 'Please complete your email verification before attempting to log in again',
  emailAlert2: 'Please check the spam folder to confirm if the email has arrived',
  today: 'Today',
  tomorrow: 'Tomorrow',
  requestQuota: '{remain}/{total} requests remaining until {day} {time}',
  badCredentials: 'User information is incorrect, will return to the home page',
  loadingError: 'Data loading error, please refresh the page or try again later',
  actionSuccess: 'Request executed successfully',
  actionsSuccess: 'Requests executed successfully',
  actionInvalid: 'Invalid request',
  outOfQuota: 'Your LLM request quota is insufficient',
  commonError: 'Failed to process request execution result information',
  template: 'Processing `{instructionName}` request failed `{validationTypeName}` validation. Please check the value of `{paramType}` parameter: {params}',
  noMoreRollback: 'No more records to rollback',
  noMoreTokens: 'Your token balance is insufficient, please contact the administrator',
  restrictionInfo: 'More usage detail',
  register: 'Login/Register',
  registerAlert: 'Please log in before trying it',
  operation: {
    document: 'Document',
    export: 'Export',
    split: 'Split',
    merge: 'Merge',
    diagram: 'Diagram',
    fitScreen: 'Adapt to screen',
  },
  chat: {
    you: 'You',
    pleaseInput: 'Please input interaction information',
    info: 'Message',
    error: 'Error',
    warning: 'Warning',
    moreHistory: 'More historical messages have been deleted',
    maxLengthReached: 'Maximum length reached',
    emptyInfoNotAllow: 'Please do not submit empty information',
    copy: 'Copy',
    retry: 'Retry',
    submit: 'submit',
    sending: 'Your request is being processed',
    cancel: 'abandon',
    unrecognizedMessage: 'Unrecognized message',
  },
  title: {
    bop: 'BOP',
    homePage: 'BOP - Redefining Software Development',
    aboutPage: 'About',
    userProfile: 'User Profile',
    classDiagram: 'Class Diagram Tool',
    classDiagramDoc: 'Class Diagram Document',
    classDiagramResults: 'Class Diagram Results',
    codeDiff: 'Diff Update',
  },
  instructions: {
    '10100': 'Delete Relationship',
    '10150': 'Delete method',
    '10200': 'Delete Class Attribute',
    '10300': 'Delete Class',
    '10350': 'Batch delete class',
    '10400': 'Add Class',
    '10450': 'Batch add classes',
    '10500': 'Add Attribute Name',
    '10600': 'Add or Modify Bidirectional Relationship',
    '10700': 'Add or Modify Unidirectional Relationship',
    '10800': 'Add or Modify Predicate',
    '10900': 'Add or Modify Relationship Type',
    '10910': 'Add Relationship Type',
    '10915': 'Add method',
    '10920': 'Change Relationship Type',
    '10930': 'Change Relationship Predicate',
    '10940': 'Change Relationship Quantity',
    '10950': 'Reverse relationship direction',
    '11000': 'Modify Attribute Constraint',
    '11150': 'Modify class attribute data type',
    '11100': 'Modify Class Attribute Name',
    '11200': 'Modify Class Name',
    '11250': 'Modify method name',
    '11300': 'Modify Diagram Name',
    '11400': 'Reset Class Diagram',
    '11500': 'Clear Class Diagram',
    '11600': 'Undo Diagram Operation',
    '20100': 'Split View',
    '20200': 'Merge View',
    '20300': 'Show Document',
    '20400': 'Show Diagram',
    '20500': 'Export Document',
    '20600': 'Export Diagram',
  },
  validateTypes: {
    '100': { validationTypeName: 'Class name should exist', paramType: 'class name' },
    '200': { validationTypeName: 'Class name should not exist', paramType: 'class name' },
    '300': { validationTypeName: 'Class name cannot be empty', paramType: 'class name' },
    '400': { validationTypeName: 'Class name should be valid', paramType: 'class name' },
    '500': { validationTypeName: 'Attribute name should exist', paramType: 'attribute name' },
    '600': { validationTypeName: 'Attribute name should not exist', paramType: 'attribute name' },
    '700': { validationTypeName: 'Attribute name cannot be empty', paramType: 'attribute name' },
    '800': { validationTypeName: 'Attribute name should be valid', paramType: 'attribute name' },
    '900': { validationTypeName: 'Constraint should be valid', paramType: 'constraint' },
    '1000': { validationTypeName: 'Relationship should exist', paramType: 'relationship' },
    '1100': { validationTypeName: 'Relationship should not exist', paramType: 'relationship' },
    '1200': { validationTypeName: 'Relationship type should be association type', paramType: 'relationship type' },
    '1300': { validationTypeName: 'Relationship type should be valid', paramType: 'relationship type' },
    '1400': { validationTypeName: 'Relationship type should not be inheritance type', paramType: 'relationship type' },
    '1500': { validationTypeName: 'Relationship quantity should be valid', paramType: 'quantity' },
    '1600': { validationTypeName: 'Predicate should be valid', paramType: 'predicate' },
    '1700': { validationTypeName: 'Predicate cannot be empty', paramType: 'predicate' },
    '1800': { validationTypeName: 'Class diagram name cannot be empty', paramType: 'class diagram name' },
'1900': { validationTypeName: 'Error resetting class diagram parameters', paramType: 'Class diagram name' },
'2000': { validationTypeName: 'Method name cannot be empty', paramType: 'Method name' },
'2100': { validationTypeName: 'Invalid method name', paramType: 'Method name' },
'2200': { validationTypeName: 'Method already exists', paramType: 'Method name' },
'2300': { validationTypeName: 'Method does not exist', paramType: 'Method name' },
'2400': { validationTypeName: 'Batch operation', paramType: 'Class diagram name' },
'2500': { validationTypeName: 'Invalid data type', paramType: 'Data type' },
'2600': { validationTypeName: 'Invalid parameter', paramType: 'parameter' },  },
  serverError: {
    common: 'Service exception, please retry or contact the site administrator.',
    unknownerror: 'Service exception, please retry or contact the site administrator',
    llmfailed: 'LLM return data error',
    rendersvgfailed: 'plantUML rendering error',
    lengthlimit: 'Dialogue data length limit error',
    outofquota: 'Request quota exhausted',
    outoftoken: 'Token exhausted',
    exportdocfailed: 'Document export error',
documentdatafailed: 'Document export error',
  },
  home: [
    {
      title: 'Programming is a Creation',
      contents: [
        'Programming is an art, coding is not',
        'Programming it\'s a creative thinking process',
        'How can we make creative thinking more streamline and efficient?',
      ],
    },
    {
      title: 'Breaking UI Boundaries',
      contents: [
        'Focus on thinking while tools handle othrs',
        'Design naturally and get feedbak immediately',
        'Freely through voice, text and simple operations',
      ],
    },
    {
      title: 'Documentation Automation',
      contents: [
        'You don\'t like writing document, I either',
        'Generating standardized design documents directly',
        'Design to document, document is design, and document to code',
      ],
    },
    {
      title: 'Paradigm of Software Dev',
      contents: [
        'Reproducibility and verification',
        'Provide standardized business describe tools',
        'Achieve end-to-end software development, starting from your mind',
      ],
    },
    {
      title: 'LLM-Powered Collaboration',
      contents: [
        'Make every decision on intelligent analysis',
        'AI isn\'t just a tool, it\'s your development partner',
        'Nearly real-time, providing professional guidance and options',
      ],
    },
    {
      title: 'Experience the Future, Today',
      contents: [
        'Experience next-generation software development',
        'To experience the AI-assisted development',
        'Program with BOP paradigm has arrived',
      ],
    },
  ],
  profile: {
    title: 'Personal Information',
    basePart: {
      title: 'Basic Information',
      userName: 'User Name',
      email: 'Email',
    },
    tokenPart: {
      title: 'Token Information',
      content: 'As a demo, we only provide limited experience. If you need more quota, please contact the author for details',
      remain: 'Remaining',
      added: 'Total Added',
      consumed: 'Total Consumed',
      request: 'Requests',
      avg: 'Average',
      addHistory: 'Add History',
      consumeByM: 'Monthly Usage',
      date: 'Date',
      count: 'Count',
      tokens: 'Tokens',
      tokenConsumed: 'Tokens Consumed',
      last30: 'Last 30 Requests',
      noData: 'No Data',
      type: 'Type',
      type0: 'Class Diagram Tool',
      type1: 'Diff Update',
    },
  },
  helpMessage: {
    line1: 'Welcome feedback on the new generation of interaction experience',
    line2: 'Let AI fulfill your wishes',
    line3: 'Free your hands and focus on your design',
    line4: 'Please refer to the document for the voice input mode',
    line5: 'Please refer to the document for the capabilities of the system',
    line6: 'Get the basic knowledge of class diagram editing from document',
  },
  codeDiff: {
    title1: 'Difference Mode Demonstration',
    title2: 'Solution',
    content1:
      'Currently, almost all tools use the full output file content mode when performing LLM-assisted code editing. The waiting time for a single file LLM request = file token count/token output speed. When the character count of the source code file reaches 5kb or 1ktokne, the waiting time is close to 20 seconds at an output speed of 50 tokens per second. For larger files, slower output speeds, or multi-file processing, it will take more time, and the length of the requested output tokens is also limited.',
    content2:
      'Our solution allows LLM to return only the changed information of the file, and making the expensive output tokens only consumed on the necessary change information and the necessary difference. This can greatly reduce the request waiting time. An average response time of 3-5 seconds can support 3-5 times the source code change data volume of the token output speed. Most single-file changes can be completed within 6 seconds, and multi-file changes can be completed within 10 seconds.',
    patten1: 'Full Mode',
    patten2: 'Difference Mode',
    requestTime: 'total seconds',
    tokenCount: 'output tokens',
    tokenSpeed: 'token/s',
    retryCount: 'retry count',
    uploadFile: 'Please use the upload button to select a file',
    lineMode: 'Line-by-Line Mode',
    columnMode: 'Side-by-Side Mode',
    diffMode: 'diff',
    fullMode: 'full',
    processFailed: 'Processing failed',
    submitFailed: 'Submission failed, please try again',
    list1: [
      'Most LLM tools use full mode to output code modification results',
      'Waiting for 1K token file output takes at least 20 seconds',
      'Some AI tools use inefficient text search for change position detection',
      'In fact, the diff format can be effectively output by LLM'
    ],
    list2: [
      'Avoid invalid and expensive output tokens',
      'Make output tokens related to the actual number of changes',
      'Changes within 1500 bytes/300 tokens take only 3-6 seconds',
      'Reduce output token cost by 50%-90%'
    ],
    learnMore: 'More details',
    fullModeWarning: 'Files larger than 8kb will fail in full mode due to output token limits'
  },
};
