import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LayoutView from '@/views/LayoutView.vue';
import HomeView from '../views/HomeView.vue';
import Profile from '../views/Profile.vue';
import CallBack from '../views/CallbackPage.vue';
import ClassDiagram from '../views/tools/ClassDiagram.vue';
import ClassDiagramResults from '@/views/tools/ClassDiagramResults.vue';
import ClassDiagramDocument from '@/views/document/ClassDiagramDocument.vue';
import CodeDiff from '../views/tools/CodeDiff.vue';
import { i18n } from '@/i18n';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: LayoutView,
    children: [
      {
        path: '',
        name: 'Home',
        component: HomeView,
        meta: { title: 'title.homePage' },
      },
      {
        path: 'about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
        meta: { title: 'title.aboutPage' },
      },
      {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: { title: 'title.userProfile' },
      },
      {
        path: '/callback',
        name: 'CallBack',
        component: CallBack,
      },
      {
        path: '/tools/class-diagram',
        name: 'ClassDiagram',
        component: ClassDiagram,
        meta: { title: 'title.classDiagram' },
      },
      {
        path: '/document/bop',
        name: 'ClassDiagramDoc',
        component: ClassDiagramDocument,
        meta: { title: 'title.classDiagramDoc' },
      },
      {
        path: '/document/class-diagram',
        component: () => import('@/views/document/bop/BOPDocument.vue'),
        meta: { title: 'title.classDiagramDoc' },
      },
      {
        path: '/tools/code-diff',
        name: 'CodeDiff',
        component: CodeDiff,
        meta: { title: 'title.codeDiff' },
      },
    ],
  },
  {
    path: '/tools/class-diagram-results',
    name: 'ClassDiagramResults',
    component: ClassDiagramResults,
    meta: { title: 'title.classDiagramResults' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const meta = to.meta as { title?: string };
  if (meta.title) {
    document.title = i18n.global.t(meta.title) || 'Default Title';
  } else {
    document.title = 'BA2Ops.com';
  }
  next();
});

export default router;
