<template>
  <div class="main">
    <h1 class="title">{{ $t('profile.title') }}</h1>
    <h2>{{ $t('profile.basePart.title') }}</h2>
    <div class="content">
      <el-row :gutter="20">
        <el-col :span="5" class="sub_title">{{ $t('profile.basePart.userName') }}</el-col>
        <el-col :span="10" class="sub_title">{{ $t('profile.basePart.email') }}</el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="5" class="value">{{ userValue?.nickname }}</el-col>
        <el-col :span="10" class="value">{{ userValue?.email }}</el-col>
      </el-row>
    </div>
    <h2>{{ $t('profile.tokenPart.title') }}</h2>
    <div class="msg">
      {{ $t('profile.tokenPart.content') }}.&nbsp;<a
        class="restiction"
        href="/document/class-diagram#limitation"
        target="_blank"
        >{{ $t('restrictionInfo') }}</a
      >
    </div>
    <div class="content">
      <el-row :gutter="20" class="sub_title">
        <el-col :span="5">{{ $t('profile.tokenPart.remain') }}</el-col>
        <el-col :span="5">{{ $t('profile.tokenPart.added') }}</el-col>
        <el-col :span="5">{{ $t('profile.tokenPart.consumed') }}</el-col>
        <el-col :span="4">{{ $t('profile.tokenPart.request') }}</el-col>
        <el-col :span="5">{{ $t('profile.tokenPart.avg') }}</el-col>
      </el-row>
      <el-row :gutter="20" class="value">
        <el-col :span="5">{{ userInfo.remain }}</el-col>
        <el-col :span="5">{{ userInfo.totalAdded }}</el-col>
        <el-col :span="5">{{ userInfo.totalConsumed }}</el-col>
        <el-col :span="4">{{ userInfo.requestCount }}</el-col>
        <el-col :span="5">{{ userInfo.preRequest }}</el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 10px">
        <el-col :span="12" class="sub_title">{{ $t('profile.tokenPart.addHistory') }}</el-col>
        <el-col :span="12" class="sub_title">{{ $t('profile.tokenPart.consumeByM') }}</el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-table :data="userInfo.addedDetails" style="width: 100%">
            <el-table-column prop="date" :label="$t('profile.tokenPart.date')" />
            <el-table-column prop="tokens" :label="$t('profile.tokenPart.tokens')" />
            <template #empty>
              <div class="custom-empty">{{ $t('profile.tokenPart.noData') }}</div>
            </template>
          </el-table>
        </el-col>
        <el-col :span="12">
          <el-table
            :data="userInfo.usagePreMonth"
            style="width: 100%"
            :default-sort="{ prop: 'date', order: 'descending' }"
          >
            <el-table-column prop="date" :label="$t('profile.tokenPart.date')" />
            <el-table-column prop="count" :label="$t('profile.tokenPart.count')" />
            <el-table-column prop="tokens" :label="$t('profile.tokenPart.tokenConsumed')" />
            <template #empty>
              <div class="custom-empty">{{ $t('profile.tokenPart.noData') }}</div>
            </template>
          </el-table>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6" class="sub_title">{{ $t('profile.tokenPart.last30') }}</el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="20">
          <el-table :data="userInfo.last30" style="width: 500px">
            <el-table-column prop="date" :label="$t('profile.tokenPart.date')" />
            <el-table-column prop="tokens" :label="$t('profile.tokenPart.tokenConsumed')" />
            <el-table-column prop="type" :label="$t('profile.tokenPart.type')">
              <template #default="scope">
                {{ $t(`profile.tokenPart.type${scope.row.type}`) }}
              </template>
            </el-table-column>
            <template #empty>
              <div class="custom-empty">{{ $t('profile.tokenPart.noData') }}</div>
            </template>
          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAuth0 } from '@auth0/auth0-vue';
import { fetchUserInfo } from '@/api/apiService';
import { useStore } from 'vuex';
import { getJSDateFromTicks } from '@/utils/utcDateTime';
import { ElMessage } from 'element-plus';

export default defineComponent({
  name: 'ProfilePage',
  components: {},
  setup() {
    const { t } = useI18n();
    const { user } = useAuth0();
    const userValue = user.value;
    const store = useStore();
    const userInfo = ref({
      remain: '-',
      totalAdded: '-',
      totalConsumed: '-',
      requestCount: '-',
      preRequest: '-',
      addedDetails: [],
      usagePreMonth: [],
      last30: [],
    });

    const load = async () => {
      const accessToken = store.getters.accessToken;
      try {
        let userData = await fetchUserInfo(accessToken);
        const values = userInfo.value;
        values.remain = userData.tokenInfo.remainTokens.toLocaleString();
        values.totalAdded = userData.tokenInfo.tokenAdded.reduce((sum, item) => sum + item.value, 0).toLocaleString();
        const consumed = userData.tokenInfo.total.reduce((sum, item) => sum + item.tokens, 0);
        const requests = userData.tokenInfo.total.reduce((sum, item) => sum + item.requestCount, 0);
        values.totalConsumed = consumed.toLocaleString();
        values.requestCount = requests.toLocaleString();
        values.preRequest = Math.round(consumed / requests);
        values.addedDetails = userData.tokenInfo.tokenAdded.map((item) => {
          const date = getJSDateFromTicks(item.dateTimeUtc); // 将微秒转换为毫秒
          const localDateTime = date.toLocaleString(); // 获取本地年月日时分秒
          return {
            tokens: item.value.toLocaleString(),
            date: localDateTime,
          };
        });
        values.usagePreMonth = userData.tokenInfo.total.map((item) => {
          var date = getJSDateFromTicks(item.ydmUtc);
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要加1并补零
          const formattedDate = `${year}/${month}`; // 格式化为 YYYY/MM
          return {
            tokens: item.tokens.toLocaleString(),
            date: formattedDate,
            count: item.requestCount,
          };
        });
        values.last30 = userData.tokenInfo.last30Usage.map((item) => {
          var tickDate = getJSDateFromTicks(item.dateTimeUtc);

          const localDateTime = tickDate.toLocaleString(); // 获取本地年月日时分秒
          return {
            tokens: item.tokens.toLocaleString(),
            date: localDateTime,
            type: item.type, // Add type property
          };
        });
      } catch {
        ElMessage.error(t('servrError'));
      }
    };

    watchEffect(() => {
      if (user.value) {
        load(user.value);
      }
    });

    return {
      userValue,
      userInfo,
    };
  },
});
</script>

<style scoped>
.main {
  max-width: 1024px;
  margin: 20px auto auto;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 6px 3px #999;
}
h1 {
  margin: 0;
}
h1,
h2 {
  color: var(--el-text-color-primary);
  text-align: left;
}

.content {
  font-size: 18px;
}

.sub_title {
  color: var(--el-text-color-primary);
  text-align: left;
  font-weight: 600;
}

.value {
  color: var(--el-text-color-primary);
  text-align: left;
  align-content: end;
  font-style: italic;
}

.el-col {
  margin: 2px 0;
}
.msg {
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px;
  font-style: italic;
  color: var(--el-text-color-primary);
}

.restiction {
  color: var(--el-text-color-primary);
  font-style: italic;
  text-decoration: underline;
}
</style>
