<template>
  <div class="code-diff-container">
    <div class="info-bar">
      <el-row :gutter="20">
        <el-col :span="9">
          <div style="text-align: left">
            <div style="text-align: left; font-size: 20px; font-weight: blod">{{ $t('codeDiff.title1') }}</div>
            <ul>
              <li>{{ $t('codeDiff.list1[0]') }}</li>
              <li>{{ $t('codeDiff.list1[1]') }}</li>
              <li>{{ $t('codeDiff.list1[2]') }}</li>
              <li>{{ $t('codeDiff.list1[3]') }}</li>
            </ul>
          </div>
        </el-col>
        <el-col :span="9">
          <div style="text-align: left">
            <div style="text-align: left; font-size: 20px; font-weight: blod">{{ $t('codeDiff.title2') }}</div>
            <ul>
              <li>{{ $t('codeDiff.list2[0]') }}</li>
              <li>{{ $t('codeDiff.list2[1]') }}</li>
              <li>{{ $t('codeDiff.list2[2]') }}</li>
              <li>{{ $t('codeDiff.list2[3]') }}</li>
              <el-button size="small" type="primary" style="margin-top: 5px" @click="handleMore()">{{
                $t('codeDiff.learnMore')
              }}</el-button>
            </ul>
          </div>
        </el-col>
        <el-col :span="6">
          <table class="info-table">
            <caption>
              {{
                $t('codeDiff.patten1')
              }}
            </caption>
            <tr>
              <td style="width: 70px">{{ $t('codeDiff.tokenCount') }}</td>
              <td style="width: 30px">{{ performanceFull.tokenCount }}</td>
              <td style="width: 60px">{{ $t('codeDiff.retryCount') }}</td>
              <td style="width: 30px">{{ performanceFull.retryCount }}</td>
            </tr>
            <tr>
              <td style="width: 70px">{{ $t('codeDiff.requestTime') }}</td>
              <td style="width: 35px">{{ performanceFull.periodTime }}</td>
              <td style="width: 60px">{{ $t('codeDiff.tokenSpeed') }}</td>
              <td style="width: 30px">{{ performanceFull.tokenSpeed }}</td>
            </tr>
          </table>
          <table class="info-table">
            <caption>
              {{
                $t('codeDiff.patten2')
              }}
            </caption>
            <tr>
              <td style="width: 70px">{{ $t('codeDiff.tokenCount') }}</td>
              <td style="width: 30px">{{ performanceDiff.tokenCount }}</td>
              <td style="width: 60px">{{ $t('codeDiff.retryCount') }}</td>
              <td style="width: 30px">{{ performanceDiff.retryCount }}</td>
            </tr>
            <tr>
              <td style="width: 70px">{{ $t('codeDiff.requestTime') }}</td>
              <td style="width: 30px">{{ performanceDiff.periodTime }}</td>
              <td style="width: 60px">{{ $t('codeDiff.tokenSpeed') }}</td>
              <td style="width: 30px">{{ performanceDiff.tokenSpeed }}</td>
            </tr>
          </table>
        </el-col>
      </el-row>
    </div>
    <div class="code-diff-area">
      <div class="toolbar">
        <el-switch
          v-model="outputFormat"
          class="mb-2"
          :active-text="$t('codeDiff.columnMode')"
          :inactive-text="$t('codeDiff.lineMode')"
          @change="toggleMode"
        />
      </div>
      <CodeDiff :old-string="oldCode" :new-string="newCode" :output-format="mode" :filename="fileName" :theme="theme" />
      <div class="operation-area" ref="operationAreaRef" @mousedown="onMouseDown">
        <div style="display: flex; width: 100%">
          <el-upload
            class="upload-area"
            :limit="1"
            action="/upload"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :show-file-list="false"
          >
            <template v-slot:trigger>
              <el-button type="primary" circle>
                <el-icon><UploadFilled /></el-icon>
              </el-button>
            </template>
          </el-upload>
          <el-input
            ref="textareaRef"
            type="textarea"
            class="input-area"
            :rows="1"
            :placeholder="$t('chat.pleaseInput')"
            v-model="userIntent"
            :autosize="autosize"
            @keydown="handleKeyDown"
            @input="handleInput"
            :disabled="false"
          ></el-input>
          <div class="button-area">
            <el-switch
              v-model="diffMode"
              class="ml-2"
              inline-prompt
              style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
              :active-text="$t('codeDiff.diffMode')"
              :inactive-text="$t('codeDiff.fullMode')"
            />
            <el-button
              type="primary"
              class="submit-button"
              @click="handleSubmit"
              :loading="submitLoading"
              :disabled="isSubmitButtonDisabled"
              circle
              ><el-icon><ChatSquare /></el-icon
            ></el-button>
            <el-button class="submit-button" @click="handleClean" :disabled="isSubmitButtonDisabled" circle
              ><el-icon><DeleteFilled /></el-icon
            ></el-button>
          </div>
        </div>
        <div style="color: red; font-size: 12px; margin: 0 0 -10px">
          {{ $t('codeDiff.fullModeWarning') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, nextTick, toRefs, watch } from 'vue';
import { CodeDiff } from 'v-code-diff';
import { useI18n } from 'vue-i18n';
import { ElUpload, ElMessage } from 'element-plus';
import { ChatSquare, DeleteFilled, UploadFilled } from '@element-plus/icons-vue';
import { analysisAndModify } from '@/api/apiService';
import { useStore } from 'vuex';
import { useDark } from '@vueuse/core';
import * as prettier from 'prettier/standalone';
import * as babelParser from 'prettier/parser-babel';
import * as htmlParser from 'prettier/parser-html';
import * as cssParser from 'prettier/parser-postcss';
import * as typescriptParser from 'prettier/parser-typescript';
//import csharpParser from 'prettier-plugin-csharp';
import hljs from 'highlight.js';

export default {
  components: { CodeDiff, ElUpload, UploadFilled, DeleteFilled, ChatSquare },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const state = reactive({
      oldCode: '',
      newCode: '',
      userIntent: '',
      uploadedFiles: [],
      mode: 'side-by-side',
      floding: false,
      fileName: t('codeDiff.uploadFile'),
      submitLoading: false,
      isSubmitButtonDisabled: false,
      outputFormat: true,
      performanceFull: {
        fileName: '',
        periodTime: 0,
        tokenCount: 0,
        tokenSpeed: 0,
        retryCount: 0,
      },
      performanceDiff: {
        fileName: '',
        periodTime: 0,
        tokenCount: 0,
        tokenSpeed: 0,
        retryCount: 0,
      },
      theme: 'light',
      diffMode: false,
    });

    // 检测缩进
    function detectIndent(code) {
      const lines = code.split('\n');
      for (const line of lines) {
        if (line.startsWith(' ')) {
          return { type: 'space', width: line.match(/^ +/)[0].length };
        }
        if (line.startsWith('\t')) {
          return { type: 'tab' };
        }
      }
      return { type: 'space', width: 2 }; // 默认使用 2 个空格
    }

    // 检测语言
    const detectLanguage = (code) => {
      const result = hljs.highlightAuto(code);
      return result.language || 'javascript'; // 默认使用 JavaScript
    };

    // 格式化代码
    const formatCode = async (code) => {
      const indent = detectIndent(code);
      let language = detectLanguage(code);
      console.log('indent:', indent, 'language:', language);

      // Map highlight.js languages to Prettier parsers
      const parserMap = {
        javascript: 'babel',
        js: 'babel',
        typescript: 'typescript',
        ts: 'typescript',
        html: 'html',
        css: 'css',
        scss: 'scss',
        json: 'json',
        csharp: 'typescript',
        java: 'babel',
        // Add more mappings as needed
      };

      const parser = parserMap[language] || 'babel'; // Default to babel if language not mapped

      let options = {
        parser,
        plugins: [babelParser, htmlParser, cssParser, typescriptParser],
        tabWidth: indent.type === 'tab' ? 2 : indent.width,
        useTabs: indent.type === 'tab',
        semi: true,
        singleQuote: true,
        trailingComma: 'es5',
      };

      try {
        return await prettier.format(code, options);
      } catch (error) {
        // console.error('格式化失败:', error);
        return code; // 如果格式化失败，返回原始代码
      }
    };

    const isDark = useDark();

    // Watch for theme changes
    watch(
      isDark,
      (newValue) => {
        state.theme = newValue ? 'dark' : 'light';
      },
      { immediate: true }
    );

    const textareaRef = ref(null);
    const textarea = ref('');
    const autosize = ref({ minRows: 1, maxRows: 6 });
    const operationAreaRef = ref(null);

    const updateAutosize = () => {
      const pageHeight = window.innerHeight;
      const maxRows = Math.floor((pageHeight * 0.4) / 24); // 假设每行高度为24px
      autosize.value = { minRows: 1, maxRows };
    };

    const handleMore = () => {
      switch (store.getters.currentLanguage) {
        case 'zh-CN':
          window.open(
            'https://github.com/BA2Ops/BOP/blob/master/zh-CN/202412/%E5%B7%AE%E5%BC%82%E7%BC%96%E8%BE%91/README.md',
            '_blank'
          );
          break;
        case 'zh-TW':
          window.open(
            'https://github.com/BA2Ops/BOP/blob/master/zh-TW/202412/%E5%B7%AE%E7%95%B0%E7%B7%A8%E8%BC%AF/README.md',
            '_blank'
          );
          break;
        default:
          window.open('https://github.com/BA2Ops/BOP/blob/master/en/202412/DiffEditing/README.md', '_blank');
      }
    };

    const handleKeyDown = (event) => {
      // 检查按下的键是否为 'Enter'，并且没有按下 Alt、Ctrl 或 Shift 键
      if (event.key === 'Enter' && !event.altKey && !event.ctrlKey && !event.shiftKey) {
        // 阻止默认的 'Enter' 键行为（通常是换行）
        event.preventDefault();
        // 检查输入框的内容是否为空（去掉前后空格后）
        if (textarea.value.trim() == '') {
          // 如果为空，显示警告信息
          ElMessage.warning(t('chat.emptyInfoNotAllow'));
        } else {
          // 如果不为空，调用提交函数
          handleSubmit();
        }
      }
      // 检查按下的键是否为 'Enter'，并且按下了 Ctrl 或 Alt 键
      else if (event.key === 'Enter' && (event.ctrlKey || event.altKey)) {
        // 阻止默认的 'Enter' 键行为
        event.preventDefault();

        // 在输入框中添加一个换行符
        textarea.value += '\n';
      }
    };

    const handleInput = (e) => {
      textarea.value = e;
    };

    const handleChange = (file, fileList) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        state.oldCode = e.target.result;
      };
      reader.readAsText(file.raw);
      state.uploadedFiles = [file.name];
      state.fileName = file.name;
    };

    const handleSubmit = async () => {
      state.submitLoading = true;
      state.isSubmitButtonDisabled = true;

      try {
        state.newCode = '';
        const accessToken = store.getters.accessToken;
        const payload = {
          fileItems: {
            [state.fileName]: state.oldCode,
          },
          userMessage: state.userIntent,
          cultureInfo: locale.value,
          mode: state.diffMode ? 'diff' : 'full',
        };

        const response = await analysisAndModify(payload, accessToken);

        if (response.state === 0) {
          const feedBackFileName = Object.keys(response.feedBackFiles)[0];
          // 格式化并赋值
          //state.newCode = await formatCode(response.feedBackFiles[feedBackFileName] || '');
          state.newCode = response.feedBackFiles[feedBackFileName] || '';
          // Success
          state.newCode = response.feedBackFiles[feedBackFileName] || '';
          if (state.diffMode == false) {
            state.performanceFull.fileName = feedBackFileName;
            state.performanceFull.periodTime = (response.milliseconds / 1000).toFixed(1);
            state.performanceFull.tokenCount = response.responseToken;
            state.performanceFull.tokenSpeed = (response.responseToken / state.performanceFull.periodTime).toFixed(1);
          } else {
            state.performanceDiff.fileName = feedBackFileName;
            state.performanceDiff.periodTime = (response.milliseconds / 1000).toFixed(1);
            state.performanceDiff.tokenCount = response.responseToken;
            state.performanceDiff.tokenSpeed = (response.responseToken / state.performanceDiff.periodTime).toFixed(1);
          }
        } else {
          ElMessage.error(response.errorMessage || t('codeDiff.processFailed'));
        }
      } catch (error) {
        console.error('提交失败:', error);
        ElMessage.error(t('codeDiff.submitFailed'));
      } finally {
        state.submitLoading = false;
        state.isSubmitButtonDisabled = false;
        //state.userIntent = '';
      }
    };

    const handleClean = () => {
      state.userIntent = '';
      state.uploadedFiles = [];
      state.oldCode = '';
      state.newCode = '';
      state.fileName = t('codeDiff.uploadFile');
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const handleRemove = (file, fileList) => {};

    const beforeUpload = (file) => {
      const isValidFileType = file.type === 'application/json'; // Example validation
      if (!isValidFileType) {
        //this.$message.error('Upload file type is incorrect!');
      }
      return isValidFileType;
    };

    const toggleMode = () => {
      state.mode = state.outputFormat ? 'side-by-side' : 'line-by-line';
    };

    const toggleFloding = () => {
      state.floding = !state.floding;
    };

    const isDragging = ref(false);
    const startX = ref(0);
    const startY = ref(0);
    const dragOffsetX = ref(0);
    const dragOffsetY = ref(0);

    const onMouseDown = (event) => {
      // Check if the clicked element or its parents have the class "input-area"
      let targetElement = event.target;
      while (targetElement != null) {
        if (targetElement.classList && targetElement.classList.contains('input-area')) {
          return; // Don't initiate drag if clicked on input-area
        }
        targetElement = targetElement.parentElement;
      }

      isDragging.value = true;
      startX.value = event.clientX;
      startY.value = event.clientY;
      dragOffsetX.value = operationAreaRef.value.offsetLeft;
      dragOffsetY.value = operationAreaRef.value.offsetTop;
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    };

    const onMouseMove = (event) => {
      if (isDragging.value) {
        const newX = event.clientX - startX.value + dragOffsetX.value;
        const newY = event.clientY - startY.value + dragOffsetY.value;
        operationAreaRef.value.style.left = newX + 'px';
        operationAreaRef.value.style.top = newY + 'px';
      }
    };

    const onMouseUp = () => {
      isDragging.value = false;
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    return {
      ...toRefs(state),
      textareaRef,
      operationAreaRef,
      autosize,
      handleKeyDown,
      handleInput,
      handleChange,
      handleSubmit,
      handleClean,
      handleRemove,
      beforeUpload,
      toggleMode,
      toggleFloding,
      onMouseDown,
      onMouseMove,
      onMouseUp,
      handleMore,
    };
  },
};
</script>

<style scoped>
.code-diff-container {
  display: flex;
  flex-direction: column;
  margin: 6px;
}
.info-bar {
  height: 150px;
  display: flex;
  align-items: baseline;
  border-radius: 10px;
  box-shadow: 0 0 6px 3px #999;
  margin-bottom: 10px;
  color: var(--el-text-color-primary);
  padding: 20px;
}

:deep(.el-row) {
  width: 100%;
  margin: 0 !important;
}

.grid-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--el-fill-color-light);
  border-radius: 6px;
  padding: 20px;
}

.code-diff-area {
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 6px 3px #999;
}
.code-diff-view {
  margin: 0;
  height: calc(100vh - 286px);
  min-height: 200px;
  background-color: var(--el-fill-color);
  color: #1890ff;
}
.code-diff-view .diff-table .blob-code .blob-code-inner {
  color: var(--el-text-color-primary) !important;
}
.toolbar {
  position: absolute;
  right: 350px;
  top: 5px;
  flex-grow: 1;
  z-index: 2;
}

.input-area {
  flex-grow: 1;
  margin: 0 5px;
}
.operation-area {
  background-color: #000;
  position: fixed;
  z-index: 3;
  top: 350px;
  left: 50px;
  width: 40%;
  min-width: 300px;
  margin: 0 auto;
  border: solid 1px #ccc;
  border-radius: 10px;
  padding: 15px 10px;
  cursor: move;
}

.operation-area:hover {
  cursor: move;
}
.button-area {
  display: flex;
  flex-direction: row;
}
.upload-area {
  margin-right: 3px;
}
.el-button {
  margin-left: 3px;
}
.submit-button {
  margin: 0 3px !important;
}

:deep(.el-switch__label) {
  color: #000;
}

.info-table {
  width: 100%;
  margin: 0 0 20px 0;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.info-table td {
  padding: 2px;
  font-size: 12px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.info-table td:last-child {
  border-right: none;
}

.info-table tr:last-child td {
  border-bottom: none;
}

.info-table td:nth-child(1),
.info-table td:nth-child(3),
.info-table td:nth-child(5) {
  background-color: #e6f3ff; /* 标题列使用中等蓝色 */
  color: #1890ff; /* 标题文字使用深蓝色 */
  font-weight: 500;
}

.info-table td:nth-child(2),
.info-table td:nth-child(4),
.info-table td:nth-child(6) {
  background-color: lemonchiffon;
  color: #333;
}

ul {
  padding: 0;
}
</style>
